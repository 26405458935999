<template>
	<div class="Profile">
		<BackButton ref="back" />
		<SettingButton ref="setting" />
		<TextKR kr="프로필" size="small" ref="title">
			Profile
		</TextKR>
		<div class="content">
			<div class="mainText" ref="mainText">
				Let’s create a profile!
			</div>
			<div class="desc" ref="desc">
				Create a profile to save your progress<br>and get all the features.
			</div>
			<img class="illustration" src="img/views/create-profile/illustration.svg" ref="illustration">
			<div class="btn" @click="signWithGoogle" ref="googleBtn">
				Continue with Google
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" class="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z" /><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z" /><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z" /><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z" /><path fill="none" d="M0 0h48v48H0z" /></g></svg>
			</div><br>
			<div class="btn" @click="signWithGoogle" ref="appleBtn">
				Continue with Apple
				<svg viewBox="0 0 842 1e3" xmlns="http://www.w3.org/2000/svg"><path d="M702 960c-54.2 52.6-114 44.4-171 19.6-60.6-25.3-116-26.9-180 0-79.7 34.4-122 24.4-170-19.6-271-279-231-704 77-720 74.7 4 127 41.3 171 44.4 65.4-13.3 128-51.4 198-46.4 84.1 6.8 147 40 189 99.7-173 104-132 332 26.9 396-31.8 83.5-72.6 166-141 227zM423 237C414.9 113 515.4 11 631 1c15.9 143-130 250-208 236z" /></svg>
			</div><br>
			<div class="createBtn" ref="createBtn">
				Create Profile
			</div>
			<div class="login" ref="login">
				Already have a profile? <span>Login</span>
			</div>
			<!-- <div class="btn" @click="signWithEmail">
				Continue with Email
				<svg enable-background="new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
					<path d="m454.4 90.667h-399.79c-33.706 0-54.613 19.626-54.613 51.093v228.37c0 30.613 21.333 51.2 53.12 51.2h401.28c32.853 0 57.6-21.973 57.6-51.2v-228.37c0-31.04-22.613-51.093-57.6-51.093zm-399.68 21.333h399.68c5.973 0 16.427 1.067 24.533 6.4l-214.08 172.16-232.43-172.91c5.44-3.626 12.8-5.653 22.293-5.653zm435.95 258.13c0 19.627-18.24 29.867-36.267 29.867h-401.28c-19.947 0-31.787-11.2-31.787-29.867v-228.37c0-1.813 0.213-3.733 0.427-5.547l237.01 176.32c3.947 2.88 9.28 2.773 13.013-0.213l218.24-175.47c0.32 1.6 0.533 3.307 0.64 5.013l1e-3 228.27z" />
				</svg>
			</div> -->
			<!-- <p style="margin-top:30px">
				Connected you can save your progress, book teacher and access to all the Kimbapp content! :D
			</p> -->
			<!-- <div class="register">
				<p>Don't have an account? Create one for free</p>
				<div class="btn registerbtn" @click="toRegister">
					Create a new account
				</div>
			</div> -->
		</div>
		<!-- <form action="#" @submit.prevent="submit" v-if="showEmail">
			<p>Login with Email</p>
			<div>
				<input
					id="email"
					type="email"
					name="email"
					placeholder="Email login"
					value
					required
					autofocus
					v-model="form.email"
				>
			</div>
			<div>
				<input
					id="password"
					type="password"
					name="password"
					placeholder="Password login"
					required
					v-model="form.password"
				>
			</div>
			<button type="submit" class="btn2">
				Connect
			</button>
		</form> -->
	</div>
</template>

<script>
import { Quad, TimelineMax } from 'gsap/all'
import UserData from '@/data/UserData'
import SettingButton from '@/components/SettingButton.vue'
import Firebase from '@/data/Firebase'
import router from '@/router'

export default {
	name: 'Profile',
	components: {
		SettingButton: SettingButton
	},
	computed: {
		isLogin () {
			return UserData.isLogin.value
		}
	},
	methods: {
		transitionIn () {
			const tl = new TimelineMax({ delay: 0.1 })
			tl.staggerFromTo([this.$refs.mainText, this.$refs.desc, this.$refs.illustration, this.$refs.googleBtn, this.$refs.appleBtn, this.$refs.createBtn, this.$refs.login], 0.5, { opacity: 0, y: 50 }, { opacity: 1, y: 0, ease: Quad.easeOut }, 0.1, 0)
		},
		transitionOut (cb) {
			const tl = new TimelineMax({ delay: 0.1 })
			tl.staggerTo([this.$refs.mainText, this.$refs.desc, this.$refs.illustration, this.$refs.googleBtn, this.$refs.appleBtn, this.$refs.createBtn, this.$refs.login], 0.5, { opacity: 0, y: -50 }, 0.05, 0, cb)
			this.$refs.back.hide()
			this.$refs.title.hide()
			this.$refs.setting.hide()
		},
		signWithGoogle () {
			Firebase.signInWithGoogle().then((isLogin) => {
				if (isLogin) {
					router.replace('profile')
				}
			})
		},
		signWithApple () {
			Firebase.signInWithApple()
		},
		goCreateProfile () {

		},
		goLogin () {

		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.transitionIn()
		})
	},
	beforeRouteLeave (to, from, next) {
		this.transitionOut(next)
	}
}
</script>

<style lang="stylus" scoped>
.Profile
	.content
		padding 0 20px
		.mainText
			font-family: NanumGothic
			font-size: 28px
			font-style: normal
			font-weight: 700
			line-height: 26px
			letter-spacing: -0.02em
			text-align: center
			color : rgba(46, 46, 46, 1)
			margin-bottom 20px
			margin-top 20px
		.desc
			font-family: Neue Montreal
			font-size: 15px
			font-style: normal
			font-weight: 400
			line-height: 18px
			letter-spacing: 0em
			text-align: center
			color: rgba(130, 130, 130, 1)
		.illustration
			margin-top 20px
	form
		margin-top 30px
		text-align center
		font-size 13px
		label
			width 100px
			text-align right
			padding-right 20px
			display inline-block
		input
			user-select none
			width 150px
			border none
			box-shadow none
			background #fff
			padding 10px 15px
		div
			margin-bottom 20px
		.btn2
			padding 10px 15px
			background #fff
			border none
			box-shadow none
			border-radius 15px
	.btn
		font-size 13px
		line-height 18px
		padding 10px 20px
		text-align left
		background #fff
		display inline-block
		border-radius 25px
		margin-top 20px
		min-width 150px
		user-select none
		cursor pointer
		box-shadow: 0px 18px 63px rgba(249, 27, 91, 0.09), 0px 4.02054px 14.0719px rgba(249, 27, 91, 0.0536497), 0px 1.19702px 4.18956px rgba(249, 27, 91, 0.0363503)
		border-radius: 30px

		svg, img
			width 18px
			vertical-align middle
			margin-right 5px
			margin-left 10px
		&.registerbtn
			text-align center

	.createBtn

		font-family: Neue Montreal
		font-style: normal
		font-weight: 500
		font-size: 15px
		line-height: 18px
		text-align: center

		color: #FFFFFF

		background-clip: padding-box /* !importanté */
		border: solid 2px transparent /* !importanté */
		border-radius: 40px
		padding 10px 20px
		position relative
		display inline-block
		background: linear-gradient(180deg, rgba(255, 83, 121, 0.7) 0%, #FF5379 100%)
		margin-top 30px
		&:before {
			content: ''
			position: absolute
			top: 0; right: 0; bottom: 0; left: 0
			background #0ff
			z-index: -1
			margin: -2px
			border-radius: inherit
			background: linear-gradient(100.57deg, rgba(255, 83, 121, 0.5), rgba(255, 83, 121, 1) )
		}
	.login
		position: absolute
		width: 209px
		height: 15px
		left: 83px
		top: 617px

		font-family: Neue Montreal
		font-style: normal
		font-weight: normal
		font-size: 15px
		line-height: 18px
		text-align: center
		margin-top 30px

		color: #828282
		span
			color: #FF5379
</style>
